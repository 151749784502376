import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'amfe-flexible'
import fastclick from 'fastclick'
import 'normalize.css/normalize.css'
import { Calendar, NoticeBar, Steps, Step, Icon, Circle, Row, Col, Progress, PullRefresh, Sticky, Checkbox, CheckboxGroup, Grid, GridItem, Image as VanImage, Search, Collapse, CollapseItem, Button, Cell, CellGroup, NavBar, Toast, Tab, Tabs, Cascader, Popup, Field, Form, Picker, DatetimePicker, Divider, Loading, RadioGroup, Radio } from 'vant'

createApp(App)
  .use(store)
  .use(Calendar)
  .use(NoticeBar)
  .use(Steps)
  .use(Step)
  .use(Icon)
  .use(Circle)
  .use(Col)
  .use(Row)
  .use(Progress)
  .use(Sticky)
  .use(Checkbox)
  .use(PullRefresh)
  .use(CheckboxGroup)
  .use(Grid)
  .use(GridItem)
  .use(VanImage)
  .use(Search)
  .use(Button)
  .use(Collapse)
  .use(CollapseItem)
  .use(Cell)
  .use(CellGroup)
  .use(NavBar)
  .use(Toast)
  .use(Tab)
  .use(Tabs)
  .use(Cascader)
  .use(Popup)
  .use(Field)
  .use(Form)
  .use(Picker)
  .use(DatetimePicker)
  .use(Divider)
  .use(Loading)
  .use(RadioGroup)
  .use(Radio)
  .use(router)
  .mount('#app')
